
// Offsets the drip and any text that should be positioned inside it.
$primary-offset: 10rem;

// Offsets stuff that should show during the fill.
$secondary-offset: 14rem;

// COLOUR
$coffee: #804D33;

$pour: 10s;
$fill-line: 20%;

@media (max-width: 575px) {
    // Offsets
    .personal-intro-drip,
    .personal-intro-greeting {
        left: 3rem
    }

    .personal-intro-tagline,
    .personal-intro-name,
    .personal-intro-welcome {
        left: 7rem;
    }

    // Widths + Font sizes
    .personal-intro-container img {
        width: 15rem;
    }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
    // Offsets
    .personal-intro-drip,
    .personal-intro-greeting {
        left: 3rem
    }

    .personal-intro-tagline,
    .personal-intro-name,
    .personal-intro-welcome {
        left: 7rem;
    }

    // Widths + Font sizes
    .personal-intro-container img {
        width: 15rem;
    }


}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
    // Offsets
    .personal-intro-drip,
    .personal-intro-greeting {
        left: 4rem
    }

    .personal-intro-tagline,
    .personal-intro-name,
    .personal-intro-welcome {
        left: 8rem;
    }

    // Widths + Font sizes
    .personal-intro-container img {
        width: 20rem;
        right: -6rem
    }
    .personal-intro-greeting h1,
    .personal-intro-welcome,
    .personal-intro-tagline {
        font-size: 2em;
    }
    .personal-intro-name {
        font-size: 4em;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1999px) {
    // Offsets
    .personal-intro-drip,
    .personal-intro-greeting {
        left: 8rem
    }

    .personal-intro-tagline,
    .personal-intro-name,
    .personal-intro-welcome {
        left: 12rem;
    }

    // Widths + Font sizes
    .personal-intro-container img {
        width: 25rem;
        right: -5rem
    }
    .personal-intro-greeting h1,
    .personal-intro-welcome,
    .personal-intro-tagline {
        font-size: 2.5em;
    }
    .personal-intro-name {
        font-size: 5em;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    // Offsets
    .personal-intro-drip,
    .personal-intro-greeting {
        left: 10rem
    }

    .personal-intro-tagline,
    .personal-intro-name,
    .personal-intro-welcome {
        left: 14rem;
    }

    // Widths + Font sizes
    .personal-intro-container img {
        width: 30rem;
        right: -8rem;
    }
    .personal-intro-greeting h1,
    .personal-intro-welcome,
    .personal-intro-tagline {
        font-size: 3em;
    }
    .personal-intro-name {
        font-size: 6em;
    }

}

@media (min-width: 1500px) {
    .personal-intro-container img {
        width: 30rem;
        right: 0rem;
    }
}



.personal-intro-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    min-height: 40rem;
    animation: intro-fill-backdrop $pour linear forwards;

    &.personal-intro-paused {
        .personal-intro-container {
            animation-play-state: paused !important;

            .personal-intro-drip {
                &:before, &:after {
                    animation-play-state: paused !important;
                }
            }
            .personal-intro-backdrop {
                animation-play-state: paused !important;
            }
        }
    }
    
    img {
        position: absolute;
        bottom: 0;
        transition: .5s;
    }
    .personal-intro {


        .personal-intro-greeting {
            position: absolute;
            top: calc(#{$fill-line} + 2rem);

            h1 {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-50%);
            }
        }

        // Your friendly neighborhood support!
        .personal-intro-tagline {
            position: absolute;
            bottom: 2rem;
        }

        .personal-intro-name {
            position: absolute;
            bottom: 10rem;
        }
        .personal-intro-welcome {
            position: absolute;
            top: calc(#{$fill-line} + 2rem);
        }

        .personal-intro-drip {
            position: absolute;
            display: block;
            top: -3rem;
            height: calc(100% + 3rem);
            
            &:before, &:after {
                animation-delay: 1s;
                position: absolute;
                content: '';
                transform: translateX(-50%);
                border-radius: 0 0 10em 10em;
                background-color: $coffee;
            }
            
            &:before {
                animation: intro-flow $pour linear forwards;
            }
            &:after {
                animation: intro-flow-big $pour linear forwards;
            }
        }
        .personal-intro-backdrop {
            animation: intro-fill $pour ease-out forwards;
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;
            background-color: $coffee;
        }
    }
}

@keyframes intro-fill {
    10% {
        height: 0;
    }
    40% {
        height: 15%;
    }
    100% {
        height: calc(100% - #{$fill-line});
    }
}

@keyframes intro-flow-big {
    32% {
        height: 0;
        width: 6rem;
    }
    40% {
        height: calc(100% + 2rem);
    }
    65% {
        width: 8rem;
    }
    80% {
        width: 8rem;
    }
    97% {
        border-radius: 10em;
        top: 0;
        height: 100%;
        width: .5rem;
    }
    100% {
        border-radius: 10em 0em;
        top: 30%;
        height: 0;
        width: .5rem;
    }
}

@keyframes intro-flow {
    0% {
        height: 0;
        width: 3rem;
    }
    10% {
        height: calc(100% + 2rem);
        // width: 3.5rem;
    }
    40% {
        width: 4.5rem;
    }
}

@keyframes intro-fill-backdrop {
    0% {
        color: white;
        background-color: transparent;
    }
    // 97% {
    //     color: white;
    //     background-color: transparent;
    // }
    100% {
        color: rgb(226, 219, 208);
        background-color: rgb(226, 219, 208);
    }
}